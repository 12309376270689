import React from 'react';
import AdyenCheckout from '@adyen/adyen-web';

interface IProps {
    checkout: AdyenCheckout;
}

class CreditCardPanel extends React.Component<IProps>{

    cardComponentContainer = React.createRef<HTMLDivElement>();

    componentDidMount(){
        if (!this.cardComponentContainer.current)
            return;

        this.props.checkout
            .create('card')
            .mount(this.cardComponentContainer.current);
    }

    render() {
        return (
            <div className='CreditCardPanel'>
                <div ref={this.cardComponentContainer}>
                </div>
            </div>
        )
    }
}

export default CreditCardPanel;

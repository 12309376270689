import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { PaymentLink } from '../Models/Models';
import './PaymentLinkInvalid.scss';

interface MatchParams {
    paymentLinkId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    paymentLink: PaymentLink | undefined;
}

class PaymentLinkInvalid extends React.Component<IProps>{

    renderErrorMessage() {
        const { paymentLink, match: { params }} = this.props;

        if (!paymentLink) {
            return (
                <div>
                    <h1>Something went wrong</h1>
                    <p>An error ocurred loading the payment link.</p>
                    <p>Please <Link to={`/${params.paymentLinkId}`} onClick={() => window.location.reload()}>try again</Link> or contact your agent for other options.</p>
                </div>
            )
        } 
        
        if (paymentLink.isPaid) {
            return (
                <div>
                    <h1>Payment already made</h1>
                    <p>A payment has already been made for booking <span className="reference-no">{this.props.paymentLink?.referenceNo}</span>.</p>
                </div>
            )
        } 

        return (
            <div>
                <h1>Payment link invalid</h1>
                <p>The payment link has expired or is not valid for booking <span className="reference-no">{this.props.paymentLink?.referenceNo}</span>.</p>
                <p>Please contact your agent for options.</p>
            </div>
        )
    }

    render() {
        return (
            <div className="payment-link-invalid">
                {this.renderErrorMessage()}
            </div>
        )
    }
}

export default PaymentLinkInvalid;

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { PaymentLink, PayResponse } from '../Models/Models';
import getFormattedCurrencyString from '../../utils/getFormattedCurrencyString';

import './PaymentResult.scss';

interface MatchParams {
    paymentLinkId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
    paymentLink: PaymentLink;
    paymentAmount: number | undefined;
    paymentResponse: PayResponse | undefined;
}

class PaymentResult extends React.Component<IProps>{

    readonly defaultResponse: PayResponse = {
        isSuccess: false,
        message: 'An unexpected error occurred.',
        transactionNumber: ''
    };

    private readonly getFormattedPaymentAmount = () => getFormattedCurrencyString(
        this.props.paymentAmount || 0,
        this.props.paymentLink.divisionLocale,
        this.props.paymentLink.currencyCode);

    renderContent(){
        const { paymentLink, match: { params } } = this.props;
        const { isSuccess, message, transactionNumber } = this.props.paymentResponse || this.defaultResponse;

        if (isSuccess) {
            return (
                <div>
                    <h1>Payment successful</h1>
                    <p>
                        A payment of <span className="money">{this.getFormattedPaymentAmount()}</span> was successfully 
                        processed for booking <span className="reference-no">{paymentLink.referenceNo}</span>.
                    </p>
                    <p>
                        Please retain this transaction number for your reference <span className="transaction-no">{transactionNumber}</span>.
                    </p>
                    <p>
                        A member of our staff will contact you soon with payment confirmation.
                    </p>
                </div>
            );
        }

        return (
            <div>
                <h1>Payment failed</h1>
                <p>
                    The payment of <span className="money">{this.getFormattedPaymentAmount()}</span> for 
                    booking <span className="reference-no">{this.props.paymentLink?.referenceNo}</span> failed with 
                    the following error.
                </p>
                <p className="error-message">
                    <i className="fa fa-quote-left"></i>{message}<i className="fa fa-quote-right"></i>
                </p>
                <p>
                    Please <Link to={`/${params.paymentLinkId}`}>try again</Link> or contact your agent for other options.
                </p>
            </div>
        );
    }

    render() {
        return (
            <div className="payment-result">
                {this.renderContent()}
            </div>
        );
    }
}

export default PaymentResult;
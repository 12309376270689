import React from 'react';
import AdyenCheckout from '@adyen/adyen-web';

interface IProps {
    checkout: AdyenCheckout;
}

class PayPalButtons extends React.Component<IProps>{

    payPalComponentContainer = React.createRef<HTMLDivElement>();

    componentDidMount(){
        if (!this.payPalComponentContainer.current)
            return;

        this.props.checkout
            .create('paypal')
            .mount(this.payPalComponentContainer.current);
    }

    render() {
        return (
            <div className='PayPalButtons'>
                <div ref={this.payPalComponentContainer}>
                </div>
            </div>
        )
    }
}

export default PayPalButtons;

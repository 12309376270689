import React from 'react';
import { PaymentMethodType } from './Constants'
import './PaymentMethod.scss';

interface IProps {
    type: PaymentMethodType;
    description: string;
    iconImageUrl: string;
    selectedType: PaymentMethodType | undefined;
}

class PaymentMethod extends React.Component<IProps>{

    render() {
        const isSelected = this.props.selectedType === this.props.type;
        return (
            <div className={`payment-method ${isSelected ? '--selected' : ''}`}>
                <div className="payment-method-header">
                    <span className="radio-button"></span>
                    <img src={this.props.iconImageUrl} 
                        alt={this.props.description}></img>
                    <span>{this.props.description}</span> 
                </div>
                { isSelected && this.props.children &&
                    <div className="payment-method-content">
                        { this.props.children }
                    </div>
                }
            </div>
        )
    }
}

export default PaymentMethod;

class Brands {

    static readonly MyBali = 2;
    static readonly MyCalifornia = 7;
    static readonly MyDubai = 51;
    static readonly MyFiji = 1;
    static readonly MyHawaii = 3;
    static readonly MyHolidayCentre = 49;
    static readonly MyMacau = 47;
    static readonly MyMalaysia = 6;
    static readonly MyMaldives = 14;
    static readonly MyNewCaledonia = 10;
    static readonly MyQueensland = 5;
    static readonly MySamoa = 11;
    static readonly MySingapore = 50;
    static readonly MySolomons = 12;
    static readonly MyThailand = 4;
    static readonly MyUsa = 8;
    static readonly MyVanuatu = 9;

    static readonly MyCruises = 46;
    static readonly MyCruisesNz = 71;
    static readonly RiverCollection = 90;

    static readonly FlightCentreExclusives = 63;
    static readonly EscapesByFlybuys = 57;
    static readonly GlobalEscapes = 79;

    static readonly HolidayRewardsPlus = 76;
    static readonly AldiHolidays = 92

    static getThemeFor = (divisionId: number | undefined, brandId: number | undefined) => {
        switch (brandId) {
            case Brands.HolidayRewardsPlus:
                return 'holiday-rewards-plus';

            case Brands.MyBali:
            case Brands.MyCalifornia:
            case Brands.MyDubai:
            case Brands.MyFiji:
            case Brands.MyHawaii:
            case Brands.MyHolidayCentre:
            case Brands.MyMacau:
            case Brands.MyMalaysia:
            case Brands.MyMaldives:
            case Brands.MyNewCaledonia:
            case Brands.MySamoa:
            case Brands.MySingapore:
            case Brands.MySolomons:
            case Brands.MyThailand:
            case Brands.MyUsa:
            case Brands.MyVanuatu:
                return 'my-holiday';

            case Brands.MyQueensland:
                return divisionId === 1
                    ? 'my-holiday'
                    : 'my-queensland';

            case Brands.MyCruises:
            case Brands.MyCruisesNz:
            case Brands.RiverCollection:
                return 'my-cruises';

            case Brands.EscapesByFlybuys:
                return 'escapes-by-flybuys';

            case Brands.FlightCentreExclusives:
                return 'flight-centre-exclusives'

            case Brands.GlobalEscapes:
                return 'global-escapes'
            case Brands.AldiHolidays:
                return 'aldi-holidays'

            default:
                return 'default'
        }
    }
}

export default Brands;
import React from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import { PaymentMethodType } from './Constants'
import PaymentMethod from './PaymentMethod';
import CreditCardPanel from '../CreditCardPanel/CreditCardPanel';
import './PaymentMethodSelector.scss';

interface IProps {
    checkout: AdyenCheckout;
    onPaymentMethodSelected(type: PaymentMethodType): void;
}

interface IState {
    selectedType: PaymentMethodType | undefined
}

class PaymentMethodSelector extends React.Component<IProps>{

    state: IState = {
        selectedType: undefined
    }

    private readonly onPaymentMethodSelected = (type: PaymentMethodType) =>
        this.setState({
            selectedType: type
        },
        () => this.props.onPaymentMethodSelected(type)
    );

    render() {
        return (
            <div className="payment-method-selector">
                <ul>
                    {[
                        <PaymentMethod
                            type="creditCard"
                            description='Credit Card'
                            iconImageUrl='data:image/svg+xml;base64,PHN2ZyANCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciDQogICAgd2lkdGg9IjQwIg0KICAgIGhlaWdodD0iMjYiDQogICAgZmlsbD0ibm9uZSINCiAgICB2aWV3Qm94PSIwIDAgNDAgMjYiPg0KICAgIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDBoNDB2MjZIMHoiLz4NCiAgICA8cGF0aCBmaWxsPSIjMDAxMTJDIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zMC4xIDIxSDkuOUM4Ljg0IDIxIDggMjAuMTcgOCAxOS4xNVY2Ljg1QzggNS44MyA4Ljg1IDUgOS45IDVoMjAuMmMxLjA1IDAgMS45LjgzIDEuOSAxLjg1djEyLjNjMCAxLjAyLS44NSAxLjg1LTEuOSAxLjg1eiIgY2xpcC1ydWxlPSJldmVub2RkIi8+DQogICAgPHJlY3Qgd2lkdGg9IjUiIGhlaWdodD0iMiIgeD0iMjUiIHk9IjE3IiBmaWxsPSIjZmZmIiByeD0iLjUiLz48cGF0aCBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDEyaDI0VjlIOHYzeiIgY2xpcC1ydWxlPSJldmVub2RkIi8+DQo8L3N2Zz4NCg=='
                            selectedType={this.state.selectedType}>
                            <CreditCardPanel
                                checkout={this.props.checkout}>
                            </CreditCardPanel>
                        </PaymentMethod>,
                        <PaymentMethod
                            type='paypal'
                            description='PayPal'
                            iconImageUrl='data:image/svg+xml;base64,PHN2ZyANCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciDQogICAgdmlld0JveD0iMCAwIDQwIDI2Ij4NCiAgICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDQwdjI2SDB6Ii8+DQogICAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGg0MHYyNkgweiIvPg0KICAgIDxwYXRoIGZpbGw9IiMwMDMwODYiIGQ9Ik0yNS4zNiA1LjM2Yy0uODQtLjk1LTIuMzQtMS4zNS00LjI3LTEuMzVIMTUuNWEuOC44IDAgMDAtLjc5LjY3TDEyLjQgMTkuNDRhLjQ4LjQ4IDAgMDAuNDcuNTZoMy40NWwuODctNS41LS4wMy4xN2EuOC44IDAgMDEuNzktLjY3aDEuNjRjMy4yMiAwIDUuNzQtMS4zMSA2LjQ4LTUuMWwuMDUtLjMyYTMuNTcgMy41NyAwIDAwLS43NS0zLjIyIi8+DQogICAgPHBhdGggZmlsbD0iIzAwMzA4NiIgZD0iTTI1LjM2IDUuMzZjLS44NC0uOTUtMi4zNC0xLjM1LTQuMjctMS4zNUgxNS41YS44LjggMCAwMC0uNzkuNjdMMTIuNCAxOS40NGEuNDguNDggMCAwMC40Ny41NmgzLjQ1bC44Ny01LjUtLjAzLjE3YS44LjggMCAwMS43OS0uNjdoMS42NGMzLjIyIDAgNS43NC0xLjMxIDYuNDgtNS4xbC4wNS0uMzJhMy41NyAzLjU3IDAgMDAtLjc1LTMuMjIiLz4NCiAgICA8cGF0aCBmaWxsPSIjMDAyZjg1IiBkPSJNMTguMSA4LjZhLjcuNyAwIDAxLjctLjZoNC4zOGE5LjE1IDkuMTUgMCAwMTEuNDUuMTFsLjM3LjA3LjM0LjA5LjE3LjA1YTMuOTYgMy45NiAwIDAxLjYuMjYgMy41NyAzLjU3IDAgMDAtLjc1LTMuMjJjLS44NC0uOTUtMi4zNC0xLjM1LTQuMjctMS4zNUgxNS41YS44LjggMCAwMC0uNzkuNjdMMTIuNCAxOS40NGEuNDguNDggMCAwMC40Ny41NmgzLjQ1bC44Ny01LjUuOTMtNS45eiIvPg0KICAgIDxwYXRoIGZpbGw9IiMwMDliZGQiIGQ9Ik0yNi4xMSA4LjU4bC0uMDUuMzJjLS43NCAzLjc5LTMuMjYgNS4xLTYuNDggNS4xaC0xLjY0YS44LjggMCAwMC0uNzkuNjdMMTYuMzEgMjBsLS4yNCAxLjVhLjQyLjQyIDAgMDAuNDIuNWgyLjlhLjcuNyAwIDAwLjctLjZsLjAyLS4xNS41NS0zLjQ3LjA0LS4yYS43LjcgMCAwMS42OS0uNThoLjQzYzIuODIgMCA1LjAzLTEuMTUgNS42Ny00LjQ2YTMuOCAzLjggMCAwMC0uNTgtMy4zNSAyLjc4IDIuNzggMCAwMC0uOC0uNjEiLz4NCiAgICA8cGF0aCBmaWxsPSIjMDEyMDY5IiBkPSJNMjUuMzQgOC4yN0wyNSA4LjE4YTYuNTUgNi41NSAwIDAwLS4zNy0uMDcgOS4xIDkuMSAwIDAwLTEuNDUtLjFIMTguOGEuNy43IDAgMDAtLjcuNTlsLS45MiA1LjktLjAzLjE3YS44LjggMCAwMS43OS0uNjdoMS42NGMzLjIyIDAgNS43NC0xLjMxIDYuNDgtNS4xbC4wNS0uMzJhMy45OCAzLjk4IDAgMDAtLjYtLjI2eiIvPg0KPC9zdmc+DQo='
                            selectedType={this.state.selectedType}>
                        </PaymentMethod>
                    ].map((component, index) => (
                        <li key={index}
                            className={
                                this.state.selectedType === component.props.type ? '--selected ' 
                                : this.state.selectedType ? '--unselected'
                                : ''}
                            onClick={() => this.onPaymentMethodSelected(component.props.type)}>
                            {component}
                        </li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}

export default PaymentMethodSelector;

import React from 'react';
import './LoadingIndicator.scss';

interface IProps{
    loadingMessage: string;
}

class LoadingIndicator extends React.Component<IProps> {
    render(){
        return (
            <div className="loading-indicator">
                <div>
                    <i className="fa fa-2x fa-circle-o-notch fa-spin mr-2"></i>
                </div>
                <div>
                    {this.props.loadingMessage}
                </div>
            </div>
        );
    }
}

export default LoadingIndicator;
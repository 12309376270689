const getCardType = (card: string) => {
    switch (card) {
        case 'amex':
            return 'Amex'
        case 'cup':
            return 'China Union Pay'
        case 'diners':
            return 'Diners Club'
        case 'discover':
            return 'Discover'
        case 'jcb':
            return 'JCB'
        case 'maestro':
            return 'Maestro'
        case 'mc':
            return 'Mastercard'
        case 'visa':
            return 'Visa'
    }
    return card;
}

export default getCardType;
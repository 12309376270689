import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PaymentLink from '../PaymentLink/PaymentLink';

import 'font-awesome/css/font-awesome.min.css';
import './App.scss';

class App extends React.Component{

    render(){
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/:paymentLinkId" component={PaymentLink} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
